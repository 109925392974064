import React, { useEffect } from "react";
import "./About.css";
import "./About480px.css";
import Nav from "../../Components/Nav";
import { InterObsv } from "../../Components/Utils/InterObsv";
import AboutHeader from "./AboutHeader";
import Copyright from "../../Components/Copyright";
import AboutContent from "./AboutContent";

const About = () => {
  useEffect(() => {
    InterObsv.func();
  }, []);

  return (
    <div className="About">
      <Nav />
      <div className="About-wrapper">
        <AboutHeader />
        <div className="About-backdrop-wrapper int-obv">
          <img
            className="About-backdrop-img"
            src={require("../../Media/mine/backdrop.jpg")}
            alt="backdrop"
          />
        </div>
        <AboutContent />
        <Copyright />
      </div>
    </div>
  );
};

export default About;
