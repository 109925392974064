import React from "react";
import { Loading } from "../../../Components/Utils/loading";

function NetflixPara2({ title }) {
  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <h1 className="Work-file-h1">{title}</h1>
      <div className="Work-file-img-wrapper">
        <Loading />
        <img
          loading="lazy"
          className="Work-file-img Work-file-img-dark loading-70"
          src={require("../../../Media/works/netflix/profiles.webp")}
          alt=""
        />
      </div>
    </div>
  );
}

export default NetflixPara2;
