import React, { useState, useEffect } from "react";
import "./Netflix.css";
import { workFile } from "../../../Components/Utils/workFile";
import Copyright from "../../../Components/Copyright";
import WorkHeader from "../WorkHeader";
import NetflixWebsite from "./NetflixWebsite";
import NetflixIntro from "./NetflixIntro";
import NetflixPara1 from "./NetflixPara1";
import Technologies from "../../../Components/Technologies";
import NetflixPara2 from "./NetflixPara2";
import NetflixPara3 from "./NetflixPara3";
import NetflixPara4 from "./NetflixPara4";
import NetflixPara5 from "./NetflixPara5";

const Netflix = () => {
  const [loadIframe, setLoadIframe] = useState(false);
  const [progressStatus, setProgressStatus] = useState(0);
  const mobile = window.innerWidth < window.innerHeight;

  const [scrollListener, setScrollListener] = useState(null);

  useEffect(() => {
    workFile.initWorkFile(setProgressStatus, setScrollListener);
  }, []);

  return (
    <div
      onAnimationEnd={() => {
        setLoadIframe(true);
      }}
      className="Netflix Work-file"
    >
      <WorkHeader
        progressStatus={progressStatus}
        title="Netflix Model"
        subtitle="Model of a subscription-based OTT platform"
      />
      <NetflixWebsite loadIframe={loadIframe} />
      {mobile && <NetflixPara3 title="Video Streaming" />}
      <NetflixIntro title="Introduction" />
      <NetflixPara1 title="How it all started" />
      <Technologies logos="NetflixLogos" />
      <NetflixPara2 title="Profile Manager" />
      {!mobile && <NetflixPara3 title="Video Streaming" />}
      <NetflixPara4 title="User Authentication" />
      <NetflixPara5 title="Subscription Service" />
      <Copyright d />
    </div>
  );
};

export default Netflix;
