import React from "react";

function NetflixIntro({ title }) {
  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <h1 className="Work-file-h1">{title}</h1>
      <p className="Work-file-p Work-file-content-big">
        Netflix model is a replica of the original Netflix website. The project
        is made solely for learning purposes and is only a mockup of the
        original Netflix website. This website is subscription enabled and
        contains multiple features like user authentication, profile manager,
        video streaming, etc.
      </p>

      <p className="Work-file-p Work-file-content">
        Users can sign up for the Netflix model from their personal mail id.
        Although to access the website, the user must subscribe to a plan first.
        They can choose from different subscriptions namely Premium, Standard,
        Basic. Conversely, users can also test the website just by logging in
        through user <span className="red">'test@gmail.com'</span> and password{" "}
        <span className="red">'test@123'</span>. Users can also utilize the
        profile manager to maintain different profiles in an account.
        <br /> <br />
        <a
          href="https://netflix-model.web.app/"
          target="_blank"
          className="Link"
          rel="noreferrer"
        >
          <span className="red Work-file-content-big">
            Open website
            <img src={require("../../../Media/red-arrow.png")} alt=">" />{" "}
          </span>
        </a>
      </p>
    </div>
  );
}

export default NetflixIntro;
