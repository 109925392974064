export const InterObsv = {
  func: () => {
    const intobv = document.querySelectorAll(".int-obv");
    // const intobvimg = document.querySelectorAll(".int-obv-img");

    const pageWidth = window.innerWidth;
    const appearOptions = {
      threshold: 0,
      rootMargin: "-80px 0px -80px 0px",
    };
    const appearOptions2 = {
      threshold: 0,
      rootMargin: "-80px 0px -80px 0px",
    };
    const appearOnScroll = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          entry.target.classList.toggle("block-appear", entry.isIntersecting);
          entry.target.classList.toggle(
            "block-disappear",
            !entry.isIntersecting
          );

          // if (!entry.isIntersecting) {
          //   return;
          // } else {
          //   console.log(entry.target);
          //   entry.target.classList.add("block-shiftup");
          //   appearOnScroll.unobserve(entry.target);
          // }
        });
      },
      pageWidth > 480 ? appearOptions : appearOptions2
    );

    intobv.forEach((block) => {
      appearOnScroll.observe(block);
    });

    /*
     const rollOnScroll = new IntersectionObserver(function (entries) {
      entries.forEach((entry) => {
        entry.target.classList.toggle("block-roll", entry.isIntersecting);
        entry.target.classList.toggle("block-disappear", !entry.isIntersecting);
      });
    }, appearOptions);

    intobvimg.forEach((block) => {
      rollOnScroll.observe(block);
    });
     */
  },
};
