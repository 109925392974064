import React from "react";

function EstateryIntro() {
  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <h1 className="Work-file-h1">Introduction</h1>
      <p className="Work-file-p Work-file-content-big">
        Estatery is a model e-commerce app built for renting real estate. It is
        a full-stack application where the frontend is developed with React and
        Material UI hosted on Firebase and backend is developed with Node.js,
        Express.js and MongoDB hosted on Netlify. It is a responsive web
        application that can be used on mobile devices as well.
        <br />
      </p>
      <p className="Work-file-p Work-file-content">
        <a
          href="https://estatery-home.web.app/"
          target="_blank"
          className="Link"
          rel="noreferrer"
        >
          <span className="red Work-file-content-big">
            Open website
            <img src={require("../../../Media/red-arrow.png")} alt=">" />{" "}
          </span>
        </a>
      </p>
    </div>
  );
}

export default EstateryIntro;
