import React from "react";
import Github from "../../../Components/Github";
import { UnderCons } from "../../../Components/Utils/UnderCons";

function KeeperPara3({ title, mobile }) {
  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <h1 className="Work-file-h1">
        {title} {mobile ? <UnderCons /> : <UnderCons big />}
      </h1>

      <p className="Work-file-p">
        To make the app more useful and interactive, I want to include rich text
        to all the notes. This will give more options to the user to operate
        with. I am trying to achieve this through jquery. Also I am trying to
        add features like labels, reminders and make the notes draggable.
      </p>

      <Github repo="keeper" />
    </div>
  );
}

export default KeeperPara3;
