import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Nav.css";

const Nav = ({ black, work }) => {
  const navigate = useNavigate();
  const [showDir, setShowDir] = useState(false);

  const mobile = window.innerWidth / window.innerHeight < 1;

  return (
    <div className="Nav">
      <div
        onClick={() => {
          if (work) {
            navigate("/work");
            document.body.style.setProperty("background-color", "#000");
          } else {
            navigate("/");
          }
        }}
        onMouseEnter={() => {
          setShowDir(true);
        }}
        onMouseLeave={() => {
          setShowDir(false);
        }}
        className={`Nav-siddhant ${black && "Nav-black"}`}
      >
        {!mobile && "siddhant."}
        {mobile ? (
          work ? (
            "./siddhant/works"
          ) : (
            "./siddhant"
          )
        ) : (
          <div className="Nav-anim">
            {showDir ? (work ? "works" : "home") : ""}
          </div>
        )}
      </div>
    </div>
  );
};

export default Nav;
