import React from "react";

function Copyright({ d, works, contact }) {
  const year = new Date().getFullYear();
  return (
    <div
      className={`Copyright ${d && "Dark"} ${works && "Copyright-works"} ${
        contact && "Copyright-contact"
      }`}
    >
      ⓒ {year} Siddhant Lodha
    </div>
  );
}

export default Copyright;
