import React, { useState, useEffect } from "react";
import "./Scanify.css";
import "./Scanify480px.css";
import { workFile } from "../../../Components/Utils/workFile";
import Copyright from "../../../Components/Copyright";
import WorkHeader from "../WorkHeader";
import ScanifyCarousel from "./ScanifyCarousel";
import ScanifyIntro from "./ScanifyIntro";
import Technologies from "../../../Components/Technologies";
import ScanifyPara1 from "./ScanifyPara1";
import ScanifyPara2 from "./ScanifyPara2";
import ScanifyPara3 from "./ScanifyPara3";

const Scanify = () => {
  const [progressStatus, setProgressStatus] = useState(0);

  useEffect(() => {
    workFile.initWorkFile(setProgressStatus);
  }, []);

  return (
    <div className="Scanify Work-file">
      <WorkHeader
        progressStatus={progressStatus}
        title="Scanify App"
        subtitle="Shipping container number OCR app"
      />
      <ScanifyCarousel />
      <ScanifyIntro />
      <Technologies logos="ScanifyLogos" />
      <ScanifyPara1 title="Why I made it" />
      <ScanifyPara2 title="Application Flow" />
      <ScanifyPara3 title="Working" />
      <Copyright d />
    </div>
  );
};

export default Scanify;
