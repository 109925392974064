import React from "react";

function NetflixPara4({ title }) {
  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <h1 className="Work-file-h1">{title}</h1>
      <p className="Work-file-p">
        To enable user authentication in the website, I used firebase. By
        knowing a user's identity, the website can securely save user data in
        the cloud and provide the same personalized experience across all of the
        user's devices. Firebase Authentication provided backend services to
        authenticate users to the website.
      </p>
    </div>
  );
}

export default NetflixPara4;
