import React, { useState, useEffect } from "react";
import "./Estatery.css";
import { workFile } from "../../../Components/Utils/workFile";
import WorkHeader from "../WorkHeader";
import EstateryIntro from "./EstateryIntro";
import Technologies from "../../../Components/Technologies";
import EstateryWebsite from "./EstateryWebsite";
import EstateryImage from "./EstateryImage";
// import EstateryPara1 from "./EstateryPara1";
import EstateryPara2 from "./EstateryPara2";
// import EstateryPara3 from "./EstateryPara3";
import Copyright from "../../../Components/Copyright";

import "./Estatery.css";

const Estatery = () => {
  const [loadIframe, setLoadIframe] = useState(false);
  const [progressStatus, setProgressStatus] = useState(0);

  const mobile = window.innerWidth < window.innerHeight;

  useEffect(() => {
    workFile.initWorkFile(setProgressStatus);
    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, []);

  return (
    <div
      onAnimationEnd={() => {
        setLoadIframe(true);
      }}
      className="Estatery Work-file"
    >
      <WorkHeader
        progressStatus={progressStatus}
        title="Estatery"
        subtitle="Real estate renting e-commerce app"
      />
      {mobile ? <EstateryImage /> : <EstateryWebsite loadIframe={loadIframe} />}
      <EstateryIntro />
      <Technologies logos="EstateryLogos" />
      {/* <EstateryPara1 title="Working video" /> */}
      <EstateryPara2 />
      {/* <EstateryPara3 title="Work under progress" mobile={mobile} /> */}
      <Copyright d />
    </div>
  );
};

export default Estatery;
