import React from "react";
import { useNavigate } from "react-router-dom";
import Copyright from "../../Components/Copyright";
import Nav from "../../Components/Nav";
import "./Work.css";
import "./Work480px.css";

const Work = () => {
  const navigate = useNavigate();

  return (
    <div className="Work">
      <Nav />
      <div className="Work-wrapper">
        <div className="Work-project-wrapper">
          <div
            onClick={() => navigate("/work/estatery")}
            className="title Work-title"
          >
            Estatery
          </div>
          <div className="Work-desc red">
            Real estate renting e-commerce app
          </div>
        </div>
        <div className="Work-project-wrapper">
          <div
            onClick={() => navigate("/work/netflix")}
            className="title Work-title"
          >
            Netflix Model
          </div>
          <div className="Work-desc red">
            Model of a subscription-based OTT platform
          </div>
        </div>
        <div className="Work-project-wrapper">
          <div
            onClick={() => navigate("/work/scanify")}
            className="title Work-title"
          >
            Scanify App
          </div>
          <div className="Work-desc red">Shipping container code OCR app</div>
        </div>
        <div className="Work-project-wrapper">
          <div
            onClick={() => navigate("/work/keeper")}
            className="title Work-title"
          >
            Keeper
          </div>
          <div className="Work-desc red">Clone of Google keep</div>
        </div>
      </div>
      <Copyright works />
    </div>
  );
};

export default Work;
