import React from "react";
import Github from "../../../Components/Github";

function ScanifyPara3({ title }) {
  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <h1 className="Work-file-h1">{title}</h1>
      <p className="Work-file-p">
        Scanify's frontend is built with the help of{" "}
        <a
          href="https://www.appsheet.com"
          className="red"
          target="_blank"
          rel="noreferrer"
        >
          AppSheet
        </a>
        . There are majorly five views in the app, built as per the convinence
        of the involved company. The data of the app is synced with the
        corresponding Google spreadsheet.
        <ol>
          <li>
            The data of a container truck and all the relevant information is
            added through the app via a form.
          </li>
          <li>
            A row is added in the synced sheet automatically and all the data is
            updated. The image is stored in the google drive of the same google
            account.
          </li>
          <li>
            This updation triggers a script function which sends image details
            to the backend and cues it to process the image.
          </li>
          <li>
            The backend is developed with python on a lightweight web framework
            called{" "}
            <a
              href="https://flask.palletsprojects.com/en/2.0.x/"
              className="red"
              target="_blank"
              rel="noreferrer"
            >
              Flask
            </a>
            . It uses an external library{" "}
            <a
              href="https://pythonhosted.org/PyDrive/"
              className="red"
              target="_blank"
              rel="noreferrer"
            >
              PyDrive
            </a>{" "}
            to directly access the image from google drive.
          </li>
          <li>
            The text from the image is extracted with{" "}
            <a
              href="https://cloud.google.com/vision/docs/ocr/"
              className="red"
              target="_blank"
              rel="noreferrer"
            >
              Google Cloud Vision OCR
            </a>
            . Then RegEx (Regular expression) is used to recognise the desired
            number in all the text. finally, a verification is done to check if
            the number is in compliance with{" "}
            <a
              href="https://en.wikipedia.org/wiki/ISO_6346"
              className="red"
              target="_blank"
              rel="noreferrer"
            >
              ISO 6346
            </a>{" "}
            standards.
          </li>
          <li>
            The number is then added directly to the sheet with an external
            library{" "}
            <a
              href="https://docs.gspread.org/en/latest/"
              className="red"
              target="_blank"
              rel="noreferrer"
            >
              gspread
            </a>{" "}
            and the data gets synced automatically with the Scanify app.
          </li>
        </ol>
      </p>

      <Github repo="scanify-public" />
    </div>
  );
}

export default ScanifyPara3;
