import React, { useState, useEffect } from "react";
import tea from "../../Components/Utils/tea";
import random from "../../Components/Utils/random";

function AboutHeader() {
  const [teaWord, setTeaWord] = useState({ language: "Hindi", word: "चाय" });
  const [ex, setEx] = useState("!");

  useEffect(() => {
    setInterval(function () {
      setEx("");
      var index = Math.floor(Math.random() * 55);
      var characters = [];

      const interval = setInterval(() => {
        for (var i = 0; i < tea.teaAll[index].word.length; i++) {
          characters[i] = random.random[Math.floor(Math.random() * 400)];
          setTeaWord({ language: "", word: characters.join("") });
        }
      }, 100);

      setTimeout(() => {
        clearInterval(interval);
        setTeaWord(tea.teaAll[index]);
        setEx("!");
      }, 1000);
    }, 3000);
  }, []);

  useEffect(() => {}, [teaWord]);

  return (
    <div className="About-header int-obv">
      I am a developer,
      <br />
      <span className="red">fuelled</span> by{" "}
      <div className="About-tea-dropdown">
        <div className="About-tea">
          {" "}
          {teaWord.word} {ex}
        </div>
        <span className="dropdown-content-wrapper">
          <span className="dropdown-content">
            <img src={require("../../Media/tooltip.png")} alt="" />
            Translation of{" "}
            <span className="red" style={{ fontSize: "1.2em" }}>
              {teaWord.word} {teaWord.language && `(${teaWord.language})`}
            </span>{" "}
            <br />
            <hr color="#bebebe" />
            <em>Noun</em> <br />
            <span className="red">tea : /ti/</span>
            <br />
            <ul>
              <li>
                A hot drink made by infusing the dried crushed leaves of the tea
                plant in boiling water.
                <br />
                <br />{" "}
                <ul>
                  <li>
                    Katherine sipped her <span className="red">tea</span>.
                  </li>
                </ul>
              </li>
            </ul>{" "}
          </span>
        </span>
      </div>
    </div>
  );
}

export default AboutHeader;
