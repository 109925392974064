export const Tools = {
  onHoverTitles: (setTitles, action) => {
    // setTitles((prev) => {
    //   var a = prev.t;
    //   a[place] = word;
    //   console.log(a);
    //   var k = { t: a };
    //   return k;
    // });
    // setInit(false);

    if (action === "enter") {
      setTitles({ t: ["About", "Works", "Contact"] });
    } else {
      setTitles({ t: ["Hello.", "I am", "Siddhant"] });
    }
  },
  links: [
    { name: "linkedin", link: "https://www.linkedin.com/in/siddhantlodha/" },
    { name: "github", link: "https://github.com/Cydd7" },
    { name: "twitter", link: "https://twitter.com/philosoficle" },
    { name: "google", link: "mailto:siddhantlodha1999@gmail.com" },
    { name: "instagram", link: "https://www.instagram.com/philosoficle/" },
  ],
};
