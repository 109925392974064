import { InterObsv } from "../Utils/InterObsv";

export const workFile = {
  initWorkFile: (setProgressStatus) => {
    // Initiating Intersection observer
    InterObsv.func();

    // For transition effect
    document.body.style.setProperty("background-color", "#eee");

    // For initializing window from the top
    window.scrollTo(0, 0);

    const handleScroll = (e) => {
      throttleStatusBar(e, setProgressStatus);
    };

    window.addEventListener("scroll", handleScroll);

    const throttleStatusBar = throttle((e, setProgressStatus) => {
      update(e, setProgressStatus);
    }, 10);

    function throttle(cb, delay = 1000) {
      let shouldWait = false;
      let waitingArgs;
      const timeoutFunc = () => {
        if (waitingArgs == null) {
          shouldWait = false;
        } else {
          cb(...waitingArgs);
          waitingArgs = null;
          setTimeout(timeoutFunc, delay);
        }
      };

      return (...args) => {
        if (shouldWait) {
          waitingArgs = args;
          return;
        }

        cb(...args);
        shouldWait = true;

        setTimeout(timeoutFunc, delay);
      };
    }

    function update(e, setProgressStatus) {
      setProgressStatus(
        (window.scrollY / (document.body.clientHeight - window.innerHeight)) *
          100
      );
    }

    // Setting the image height individually
    const imgs70 = document.getElementsByClassName("loading-70");
    const imgs25 = document.getElementsByClassName("loading-25");
    for (let i = 0; i < imgs70.length; i++) {
      imgs70[i].addEventListener("load", function () {
        var element = imgs70[i];
        var h;
        if (window.innerWidth < window.innerHeight) {
          h = 90 * (element.naturalHeight / element.naturalWidth);
        } else {
          h = 70 * (element.naturalHeight / element.naturalWidth);
        }
        element.parentNode.style.setProperty("height", h + "vw");
      });
    }
    for (let i = 0; i < imgs25.length; i++) {
      imgs25[i].addEventListener("load", function () {
        var element = imgs25[i];
        var h = 20 * (element.naturalHeight / element.naturalWidth);
        element.parentNode.style.setProperty("height", h + "vw");
      });
    }
  },
  EstateryLogos: [
    "React_Logo_Text.webp",
    "mui_logo.webp",
    "nodejs_logo.webp",
    "Firebase_logo_text.webp",
    "mongodb_logo.webp",
  ],
  NetflixLogos: [
    "React_Logo_Text.webp",
    "Redux_logo_text.webp",
    "Firebase_logo_text.webp",
    "TMDb_logo.webp",
    "stripe_logo.webp",
  ],
  ScanifyLogos: [
    "appsheet.webp",
    "appscript.webp",
    "flask.webp",
    "logo.png",
    "heroku.webp",
  ],
  KeeperLogos: [
    "React_Logo_Text.webp",
    "Redux_logo_text.webp",
    "Firebase_logo_text.webp",
    "jquery.webp",
    "bootstrap.webp",
  ],
};
