import React from "react";
import AboutMarquee from "./AboutMarquee";

function AboutContent() {
  return (
    <div className="About-content content-block int-obv">
      <div className="About-heading">About me</div>

      <AboutMarquee />

      <p>
        Hi, I'm Siddhant, a philosophical person fond of science, technology,
        stories, and art. Fuelled by chai, I'm easily inspired and more than
        willing to follow my fascinations. I'm a passionate, expressive,
        multi-talented spirit with a natural ability to entertain and inspire. I
        am always looking to learn new skills, pick up new challenges, and do
        exciting things that matter. I love having a crazy idea and building
        something from scratch. I ensure that I am always logged into Notion on
        my phone and frequently jotting stuff down. My biggest fear is not
        having a pen and paper, just when that great idea/thought hits.
      </p>
      <p>
        You can also call me a front-end developer. I design and develop
        websites professionally plus as a hobby. I have a firm grasp of
        JavaScript fundamentals, Object-oriented programming and Web concepts. I
        research and learn best code practices to build modular and scalable
        features from scratch. I keep up-to-date with the latest trends, learn
        new web concepts and work on personal projects. Lately, I have been
        using React.js as the library for my websites, but I am open and more
        than willing to learn new technologies.
      </p>
      <div className="quote-wrapper">
        <div className="quote">
          <span className="quote-mark">“</span>
          <em>
            To see the world, things dangerous to come to, to see behind walls,
            draw closer, to find each other, and to feel. That is the purpose of
            life.
          </em>
          <span className="quote-mark">”</span>
        </div>
        <br />
        <div className="quoter">
          ― James Thurber,{" "}
          <a
            style={{ color: "inherit" }}
            href="https://www.imdb.com/title/tt0359950/"
            target="_blank"
            rel="noreferrer"
          >
            <em>The Secret Life of Walter Mitty</em>
          </a>
        </div>
      </div>
      <p>
        Above all I love curating and working on the innovative ideas poping up
        randomly. I have a thing for books, philosophy and long conversations.
        Apart from that I love gaming, animes, movies, writing, volunteering and
        getting involved in the community. Throughout my college years, I was a
        part of the organising committee of my institute's annual cultural fest{" "}
        <a
          style={{ color: "inherit" }}
          href="https://vivacity.lnmiit.ac.in/"
          target="_blank"
          rel="noreferrer"
        >
          <em>Vivacity</em>
        </a>
        . I mentored as the PR team head in the last cultural fest pre-covid,
        Vivacity’2020.
      </p>
      <a
        href="https://drive.google.com/file/d/15KrQGg_AmZaaYahUPD4UAqgeaf7Vsn9l/view?usp=sharing"
        target="_blank"
        className="Link"
        rel="noreferrer"
      >
        <span className="red Link Resume">
          Resume
          <img src={require("../../Media/red-arrow.png")} alt=">" />{" "}
        </span>
      </a>
    </div>
  );
}

export default AboutContent;
