import React, { useState, useEffect } from "react";
import "./Keeper.css";
import { workFile } from "../../../Components/Utils/workFile";
import WorkHeader from "../WorkHeader";
import KeeperIntro from "./KeeperIntro";
import Technologies from "../../../Components/Technologies";
import KeeperWebsite from "./KeeperWebsite";
import KeeperImage from "./KeeperImage";
import KeeperPara1 from "./KeeperPara1";
import KeeperPara2 from "./KeeperPara2";
import KeeperPara3 from "./KeeperPara3";
import Copyright from "../../../Components/Copyright";

const Keeper = () => {
  const [loadIframe, setLoadIframe] = useState(false);
  const [progressStatus, setProgressStatus] = useState(0);

  const mobile = window.innerWidth < window.innerHeight;

  useEffect(() => {
    workFile.initWorkFile(setProgressStatus);
  }, []);

  return (
    <div
      onAnimationEnd={() => {
        setLoadIframe(true);
      }}
      className="Keeper Work-file"
    >
      <WorkHeader
        progressStatus={progressStatus}
        title="Keeper"
        subtitle="Clone of Google keep"
      />
      {mobile ? <KeeperImage /> : <KeeperWebsite loadIframe={loadIframe} />}
      <KeeperIntro />
      <Technologies logos="KeeperLogos" />
      {/* <KeeperPara1 title="Working video" /> */}
      <KeeperPara2 title="Implementation" />
      <KeeperPara3 title="Work under progress" mobile={mobile} />
      <Copyright d />
    </div>
  );
};

export default Keeper;
