import React from "react";

function NetflixWebsite({ loadIframe }) {
  return (
    <div className="Netflix-website int-obv">
      <div className="Netflix-website-shadow">
        {loadIframe && (
          <iframe
            src="https://netflix-model.web.app/"
            title="Netflix"
            width="100%"
            height="103.5%"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </div>
    </div>
  );
}

export default NetflixWebsite;
