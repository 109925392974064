import React from "react";

function ScanifyIntro() {
  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <h1 className="Work-file-h1">Introduction</h1>
      <p className="Work-file-p Work-file-content-big">
        Scanify is a custom-made app which helps adding data of shipping
        containers to Google spreadsheets using OCR technology of{" "}
        <span className="red">Google Cloud Vision API</span>.
      </p>

      <p className="Work-file-p Work-file-content">
        The app is primarily built to recognize{" "}
        <a
          className="Inline-link "
          target="_blank"
          rel="noreferrer"
          href="https://www.bic-code.org/identification-number/"
        >
          Container Identification Number
        </a>{" "}
        and{" "}
        <a
          className="Inline-link "
          target="_blank"
          rel="noreferrer"
          href="https://en.wikipedia.org/wiki/Vehicle_registration_plates_of_India"
        >
          Vehicle Registration Number
        </a>{" "}
        from the images of shipping containers. It also makes the data more
        manageable through it's user interface. This was a freelance project and
        included custom work according to the company's needs.
      </p>
      {/* <p className="Work-file-p Work-file-content">
          - Scanify app is built on AppSheet platform. - The data is managed and
          maintained by AppScript 1. Optical Character Recognition (OCR)
          technology is used to detect text from images. <br />
          2. for Aakash Industries. They are rice exporters in Madhya Pradesh.
          3. But, the main function of the app is to detect the container no.
          and vehicle plate no. from an image of a container.
        </p> */}
    </div>
  );
}

export default ScanifyIntro;
