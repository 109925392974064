import React from "react";
import { workFile } from "./Utils/workFile";

function Technologies({ logos }) {
  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <h1 className="Work-file-h1">Technologies</h1>
      <div className="Work-file-billboard">
        {workFile[logos].map((logo) => {
          return (
            <img
              src={require("../Media/logos/" + logo)}
              alt="Logos"
              className="Work-file-billboard-img"
            />
          );
        })}
      </div>
    </div>
  );
}

export default Technologies;
