import React from "react";

function ScanifyPara1({ title }) {
  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <h1 className="Work-file-h1">{title}</h1>

      <p className="Work-file-p">
        As soon as I was done covering the fundamentals of web development, I
        was ready to pick up a project to get hands-on experience and brush up
        on my basics. I got a kickstart on my skills when a mutual friend
        connected me to{" "}
        <a
          href="https://www.aakashindustries.co.in/"
          className="red"
          target="_blank"
          rel="noreferrer"
        >
          Aakash Industries
        </a>{" "}
        who were looking for someone who can automate container data entry for
        them.
      </p>
    </div>
  );
}

export default ScanifyPara1;
