import React from "react";
import { Loading } from "../../../Components/Utils/loading";

function ScanifyPara2({ title }) {
  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <h1 className="Work-file-h1">{title}</h1>
      <p className="Work-file-p Work-file-content-big"></p>
      <div className="Work-file-img-wrapper">
        <Loading />
        <img
          loading="lazy"
          className="Work-file-img loading-70"
          src={require("../../../Media/works/scanify/flowchart.webp")}
          alt=""
        />
      </div>
    </div>
  );
}

export default ScanifyPara2;
