import React from "react";

function KeeperWebsite({ loadIframe }) {
  return (
    <div className="Scanify-website int-obv">
      <div className="Netflix-website-shadow">
        {loadIframe && (
          <iframe
            src="https://keep-model.web.app/"
            title="Netflix"
            width="100%"
            height="103.5%"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )}
      </div>
    </div>
  );
}

export default KeeperWebsite;
