import React from "react";

function Github({ repo }) {
  return (
    <a
      href={`https://github.com/Cydd7/${repo}`}
      target="_blank"
      className="Link"
      rel="noreferrer"
    >
      <span className="red Link github">
        Github repository
        <img src={require("../Media/red-arrow.png")} alt=">" />{" "}
      </span>
    </a>
  );
}

export default Github;
