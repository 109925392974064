import React from "react";
import Nav from "../../Components/Nav";

function WorkHeader({ progressStatus, title, subtitle }) {
  return (
    <>
      <div
        style={{ width: `${progressStatus}%` }}
        className="Work-progress-bar"
      ></div>
      <Nav black work />
      <div className="Work-file-title-wrapper int-obv">
        <div className="Work-file-title title-dark">{title}</div>
        <div className="Work-file-subtitle">{subtitle}</div>
      </div>
    </>
  );
}

export default WorkHeader;
