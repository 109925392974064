import React from "react";

function KeeperPara2({ title }) {
  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <h1 className="Work-file-h1">{title}</h1>
      <p className="Work-file-p">
        <ol>
          <li>The application is react-redux based.</li>
          <li>
            User authentication and cloud storage is enabled in the app via
            Firebase.
          </li>
          <li>
            The app contains a search bar to filter all the relevant notes.
          </li>

          <li>
            To get a perfect tile stucture for all the notes which is fully
            responsive and adaptive, I used David DeSandro's{" "}
            <a
              rel="noreferrer"
              href="https://masonry.desandro.com/layout.html"
              className="red"
              target="_blank"
            >
              masonry-layout
            </a>{" "}
            library. This library places all items in such an order that all the
            columns are of nearly same height, hence, making the whole structure
            more uniform.
          </li>
          <li>
            I used an npm package{" "}
            <a
              href="https://www.npmjs.com/package/react-modal"
              className="red"
              target="_blank"
              rel="noreferrer"
            >
              react-modal
            </a>{" "}
            to pop the note over the screen whenever it is edited.
          </li>
        </ol>
      </p>
    </div>
  );
}

export default KeeperPara2;
