import React from "react";
import "./AboutMarquee.css";

function AboutMarquee() {
  const TAGS = [
    "ReactJs",
    "Redux",
    "JavaScript",
    "TypeScript",
    "Node.js",
    "Express.js",
    "jQuery",
    `HTML5`,
    "CSS3",
    "Bootstrap",
    "MaterialUI",
    "C/C++",
    "Python",
    "Git/GitHub",
    "MySQL",
    "MongoDB",
  ];
  return (
    <div className="tag-list">
      <div className="outer">
        <div className="inner">
          {TAGS.map((tag) => (
            <div key={tag} className="tag">
              <span>#</span> {tag}
            </div>
          ))}
          {TAGS.map((tag) => (
            <div key={tag} className="tag">
              <span>#</span> {tag}
            </div>
          ))}
        </div>
        <div className="fade" />
      </div>
    </div>
  );
}

export default AboutMarquee;
