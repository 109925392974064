import React from "react";
import "./Contact.css";
import "./Contact480px.css";
import Nav from "../../Components/Nav";
import Copyright from "../../Components/Copyright";
import ContactForm from "./ContactForm";
import ContactLinks from "./ContactLinks";

const Contact = () => {
  return (
    <div className="Contact">
      <Nav />
      <div className="Contact-title">
        Let's <span className="red">build</span> something!
      </div>
      <div className="Contact-block">
        <p className="Contact-block-bigp">
          Got a question or proposal? I am just a click away.
        </p>

        <ContactForm />
        <ContactLinks />
      </div>
      <Copyright contact />
    </div>
  );
};

export default Contact;
