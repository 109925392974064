import React, { useState } from "react";
import { LoadingWhite } from "../../../Components/Utils/loadingWhite";
import view0 from "../../../Media/works/estatery/view0.webm";
import view1 from "../../../Media/works/estatery/view1.webm";
import view2 from "../../../Media/works/estatery/view2.webm";
import view3 from "../../../Media/works/estatery/view3.webm";
import view4 from "../../../Media/works/estatery/view4.webm";

function EstateryPara2() {
  const [vidStatus, setVidStatus] = useState(0);
  const vids = {
    vidNums: [0, 1, 2, 3, 4],
    vidNames: [view0, view1, view2, view3, view4],
    vidDesc: [
      "Searching properties with text",
      "Searching properties with location, date available and price filters",
      "Booking a property for a particular date range",
      "Unbooking a property",
      "Saving and unsaving a property for later",
    ],
  };

  function showPrev() {
    setVidStatus((prev) => {
      if (prev > 0) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  }
  function showNext() {
    setVidStatus((prev) => {
      if (prev < 4) {
        return prev + 1;
      } else {
        return prev;
      }
    });
  }

  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <h1 className="Work-file-h1">Features</h1>
      <div className="Estatery-carousel">
        <div className="Estatery-screen">
          <LoadingWhite />
          <div className="Estatery-video-wrapper">
            <video
              key={vidStatus}
              className="Estatery-video loading-25"
              loop
              autoPlay
              muted
            >
              <source src={vids.vidNames[vidStatus]} type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="Estatery-desc">{vids.vidDesc[vidStatus]}</div>
        <div onClick={showPrev} className="showPrev">
          <img src={require("../../../Media/works/scanify/left.png")} alt="<" />
        </div>
        <div onClick={showNext} className="showNext">
          <img
            src={require("../../../Media/works/scanify/right.png")}
            alt=">"
          />
        </div>
        <div className="dots">
          {vids.vidNums.map((vidNum) => {
            return (
              <div
                className={`dot ${vidStatus === vidNum && "dot-active"}`}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="content-block content-block-dark Work-file-content-block int-obv">
  //     <h1 className="Work-file-h1">Features</h1>
  //     <p className="Work-file-p">
  //       <ol>
  //         <li>Search properties with text</li>
  //         <li>
  //           Search properties with location, date available and price filters.
  //         </li>
  //         <li>Booking a property for a particular date range.</li>
  //         <li>Unbooking a property.</li>
  //         <li>Saving and unsaving a property for later.</li>
  //       </ol>
  //     </p>
  //   </div>
  // );
}

export default EstateryPara2;
