import React from "react";
import { Tools } from "../../Components/Utils/Tools";

function ContactLinks() {
  return (
    <div className="Contact-links-wrapper">
      {Tools.links.map((data) => {
        return (
          <a
            target="_blank"
            rel="noreferrer"
            href={data.link}
            className="Contact-links"
          >
            <i className={`fab fa-${data.name}`}></i>
          </a>
        );
      })}
    </div>
  );
}

export default ContactLinks;
