import React from "react";

function NetflixPara1({ title }) {
  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <h1 className="Work-file-h1">{title}</h1>

      <div className="quote-wrapper">
        <div className="quote">
          <span className="quote-mark">“</span>
          <em>Good artists copy, great artists steal.</em>
          <span className="quote-mark">”</span>
        </div>
        <div className="quoter">― Probably, Picaso</div>
      </div>

      <p className="Work-file-p">
        Being a cinephile forever, it was natural instinct to build a website
        related to movies. But why build something that has already been built?
        Well firstly, just to ride on a steep learning curve. I get to polish up
        my skills and use them in a real world plus it's fun! Secondly, if I get
        stuck somewhere or have a coder's block, I can take inspirations.
      </p>
      {/* <p className="Work-file-p">
          how I got the idea of making netflix. I have been watching movies of
          different genres be it old or new, on my laptop or in a theatre not
          necessarily with a bowl of popcorn. Watching movies is always a go-to
          escape. Movies have always brought me comfort in some or the other
          way. I have also learned a lot from, not just educational information
          but also different life lessons. I believe that it’s whether or not
          one can associate themselves with the eyes they’re looking through,
          and whether one can relate to the way the director feels and also the
          characters themselves.
        </p> */}
    </div>
  );
}

export default NetflixPara1;
