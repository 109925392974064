import React, { useState, useEffect } from "react";
import "./Home.css";
import Avatar from "./HomeAvatar.js";
import HomeTitles from "./HomeTitles";
// import TermScreen from "../Components/Screens/TermScreen";

const Home = () => {
  // ! Terminal box in browser code, commented to disable
  // const [showTerm, setShowTerm] = useState(false);
  // function boot() {
  //   setShowTerm(true);
  // }

  // !DELETE BELOW
  const [init, setInit] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setInit(false);
    }, 11000);
  }, []);

  return (
    <div id="home" className="Home">
      {/* Terminal box in browser code, commented to disable*/}
      {/* {showTerm ? (
        <TermScreen setShowTerm={setShowTerm} />
      ) : (
        <> */}
      <Avatar />
      {/* <button className="Home-terminal-btn" onClick={boot}>
            {">_"}
          </button> */}

      <div className="Home-html">
        <div style={{ margin: "0 0 1vh 0" }} className="techyTransition">
          {"<!DocType html>"}
        </div>
        <div className="techyTransition">{"<html lang=“en”>"}</div>
        <div className="Home-html-line">
          <div className="Home-level">
            <div className="techyTransition">{"<head>"}</div>
            <div className="Home-level1">
              <div style={{ margin: "0 0 1vh 0" }} className="techyTransition">
                {"<title>Siddhant Lodha</title>"}
              </div>
              <div className="techyTransition">
                {"<meta name=“description” content=“Front-end Engineer”/>"}
              </div>
            </div>

            <div className="techyTransition">{"</head>"}</div>
          </div>
          <div className="Home-level Home-body">
            <div className="techyTransition">{"<body>"}</div>
            <div className="Home-level1">
              <div className="techyTransition">
                {"<div class=“Programmer”>"}
              </div>
              <HomeTitles init={init} setInit={setInit} />
              <div className="techyTransition">{"<div>"}</div>
            </div>
            <div className="techyTransition">{"</body>"}</div>
          </div>
        </div>
        <div className="techyTransition">{"</html>"}</div>
      </div>
      {/* </>
      )} */}
    </div>
  );
};

export default Home;
