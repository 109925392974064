import React, { useState } from "react";
import { LoadingWhite } from "../../../Components/Utils/loadingWhite";
// import { Loading } from "../../../Components/Utils/loading";
import view0 from "../../../Media/works/scanify/view0.mp4";
import view1 from "../../../Media/works/scanify/view1.mp4";
import view2 from "../../../Media/works/scanify/view2.mp4";
import view3 from "../../../Media/works/scanify/view3.mp4";
import view4 from "../../../Media/works/scanify/view4.mp4";

function ScanifyCarousel() {
  const [vidStatus, setVidStatus] = useState(0);

  const vids = {
    vidNums: [0, 1, 2, 3, 4],
    vidNames: [view0, view1, view2, view3, view4],
  };

  function showPrev() {
    setVidStatus((prev) => {
      if (prev > 0) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  }
  function showNext() {
    setVidStatus((prev) => {
      if (prev < 4) {
        return prev + 1;
      } else {
        return prev;
      }
    });
  }

  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <div className="Scanify-carousel">
        <div className="phone-screen">
          <LoadingWhite />
          <div className="Scanify-video-wrapper">
            <video
              key={vidStatus}
              className="Scanify-video loading-25"
              loop
              autoPlay
              muted
            >
              <source src={vids.vidNames[vidStatus]} type="video/mp4" />
            </video>
          </div>
          <img
            loading="lazy"
            className="Work-file-img-frame"
            src={require("../../../Media/works/scanify/phoneFrame4.png")}
            alt=""
          />
        </div>
        <div onClick={showPrev} className="showPrev">
          <img src={require("../../../Media/works/scanify/left.png")} alt="<" />
        </div>
        <div onClick={showNext} className="showNext">
          <img
            src={require("../../../Media/works/scanify/right.png")}
            alt=">"
          />
        </div>
        <div className="dots">
          {vids.vidNums.map((vidNum) => {
            return (
              <div
                className={`dot ${vidStatus === vidNum && "dot-active"}`}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ScanifyCarousel;
