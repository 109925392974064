import React from "react";

function KeeperIntro() {
  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <h1 className="Work-file-h1">Introduction</h1>
      <p className="Work-file-p Work-file-content-big">
        Keeper is a simple notes keeping app inspired from{" "}
        <span className="red">Google Keep</span>. It is built with react and
        redux libraries. This app verifies the identity of every user attempting
        to gain access. Thus, providing a secure and personalized experience.
        <br />
      </p>
      <p className="Work-file-p Work-file-content">
        <a
          href="https://keep-model.web.app/"
          target="_blank"
          className="Link"
          rel="noreferrer"
        >
          <span className="red Work-file-content-big">
            Open website
            <img src={require("../../../Media/red-arrow.png")} alt=">" />{" "}
          </span>
        </a>
      </p>
    </div>
  );
}

export default KeeperIntro;
