import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./Home/About/About";
import Home from "./Home/Home";
import "./App.css";
import Contact from "./Home/Contact/Contact";
import Work from "./Home/WorkFiles/Work";
import Netflix from "./Home/WorkFiles/Netflix/Netflix";
import Keeper from "./Home/WorkFiles/Keeper/Keeper";
import Scanify from "./Home/WorkFiles/Scanify/Scanify";
import Estatery from "./Home/WorkFiles/Estatery/Estatery";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/work" element={<Work />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/work/estatery" element={<Estatery />} />
          <Route path="/work/netflix" element={<Netflix />} />
          <Route path="/work/keeper" element={<Keeper />} />
          <Route path="/work/scanify" element={<Scanify />} />
          <Route path="*" element={<></>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
