import React from "react";
import Demo from "../../../Media/works/netflix/demo.mp4";

function NetflixPara3({ title }) {
  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <h1 className="Work-file-h1">{title}</h1>
      {/* <p className="Work-file-p">
          Searches trailer url in the data fetched from TMdB api. Used youtube
          embed
        </p> */}
      <div className="Work-file-vid-wrapper">
        <video className="Work-file-vid" loop autoPlay muted>
          <source src={Demo} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export default NetflixPara3;
