var teaAll = [
  { language: "Amharic", word: "ሻይ" },
  { language: "Arabic", word: "شاي" },
  { language: "Armenian", word: "թեյ" },
  { language: "Azerbaijani", word: "çay" },
  { language: "Bengali", word: "চা" },
  { language: "Bulgarian", word: "чай" },
  { language: "Chinese", word: "茶" },
  { language: "Czech", word: "čaj" },
  { language: "Finnish", word: "teetä" },
  { language: "French", word: "thé" },
  { language: "Georgian", word: "ჩაი" },
  { language: "German", word: "tee" },
  { language: "Greek", word: "τσάι" },
  { language: "Gujarati", word: "ચા" },
  { language: "Hausa", word: "shayi" },
  { language: "Hawaiian", word: "tī" },
  { language: "Hebrew", word: "תה" },
  { language: "Hindi", word: "चाय" },
  { language: "Irish", word: "tae" },
  { language: "Italian", word: "tè" },
  { language: "Japanese", word: "お茶" },
  { language: "Kannada", word: "ಚಹಾ" },
  { language: "Kazakh", word: "шай" },
  { language: "Khmer", word: "តែ" },
  { language: "Korean", word: "차" },
  { language: "Lao", word: "ຊາ" },
  { language: "Latin", word: "tea" },
  { language: "Latvian", word: "tēja" },
  { language: "Luxembourgish", word: "téi" },
  { language: "Malayalam", word: "ചായ" },
  { language: "Maori", word: "tī" },
  { language: "Marathi", word: "चहा" },
  { language: "Mongolian", word: "цай" },
  { language: "Nepali", word: "चिया" },
  { language: "Nyanja", word: "tiyi" },
  { language: "Pashto", word: "چاى" },
  { language: "Persian", word: "چای" },
  { language: "Portuguese", word: "chá" },
  { language: "Punjabi", word: "ਚਾਹ" },
  { language: "Romanian", word: "ceai" },
  { language: "Russian", word: "чай" },
  { language: "Serbian", word: "чај" },
  { language: "Shona", word: "tii" },
  { language: "Sindhi", word: "چانھ" },
  { language: "Sinhala", word: "තේ" },
  { language: "Somali", word: "shaah" },
  { language: "Spanish", word: "té" },
  { language: "Swahili", word: "chai" },
  { language: "Tajik", word: "чой" },
  { language: "Tamil", word: "தேநீர்" },
  { language: "Telugu", word: "టీ" },
  { language: "Thai", word: "ชา" },
  { language: "Ukrainian", word: "чай" },
  { language: "Urdu", word: "چائے" },
  { language: "Uzbek", word: "choy" },
  { language: "English", word: "tea" },
];

var a = {
  teaAll,
};

export default a;
