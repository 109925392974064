import React from "react";
import { Loading } from "../../../Components/Utils/loading";
import Github from "../../../Components/Github";

function NetflixPara5({ title }) {
  return (
    <div className="content-block content-block-dark Work-file-content-block">
      <h1 className="Work-file-h1 int-obv">{title}</h1>

      <p className="Work-file-p int-obv">
        I used a firebase extension called{" "}
        <a
          className="red"
          href="https://firebase.google.com/products/extensions/stripe-firestore-stripe-payments"
        >
          Run Payments with Stripe
        </a>{" "}
        to run subscription and payment utilities. With the help of Stripe
        Checkout, users are able to securely process one-time payments on the
        web. It helps in creating subscriptions for the users and manage access
        control via Firebase Authentication. This extension syncs customers
        subscription status with the Cloud Firestore and adds custom claims
        using Firebase Authentication for convenient access control in the
        application. All the profile, billing, and tax information of a customer
        are stored for subscriptions and invoices.
      </p>
      <div className="Work-file-img-wrapper int-obv">
        <Loading />
        <img
          loading="lazy"
          className="Work-file-img loading-70"
          src={require("../../../Media/works/netflix/customers.webp")}
          alt=""
        />
      </div>
      <p className="Work-file-p int-obv">
        I created 3 products or subscription services similar to original
        Netflix website i.e. Basic, Standard and Premium.
      </p>
      <div className="Work-file-img-wrapper int-obv">
        <Loading />
        <img
          loading="lazy"
          className="Work-file-img loading-70"
          src={require("../../../Media/works/netflix/products.webp")}
          alt=""
        />
      </div>

      <p className="Work-file-p int-obv">
        On Stripe’s dashboad, financial reports helps to understand and
        reconcile the activity in the account. We can view summary reports in
        the dashboard or download itemised transaction data as a CSV file.
      </p>
      <div className="Work-file-img-wrapper int-obv">
        <Loading />
        <img
          loading="lazy"
          className="Work-file-img loading-70"
          src={require("../../../Media/works/netflix/report-today.webp")}
          alt=""
        />
      </div>

      {/* <div className="Work-file-img-wrapper">
          <Loading />
          <img
            loading="lazy"
            className="Work-file-img loading-70"
            src={require("../../Media/works/netflix/reports.webp")}
            alt=""
          />
        </div> */}
      <Github repo="netflix-model" />
    </div>
  );
}

export default NetflixPara5;
