import React from "react";
import { Loading } from "../../../Components/Utils/loading.js";

function EstateryImage() {
  return (
    <div className="content-block content-block-dark Work-file-content-block int-obv">
      <div className="Work-file-img-wrapper">
        <Loading />
        <img
          loading="lazy"
          className="Work-file-img Work-file-img-dark loading-70"
          src={require("../../../Media/works/estatery/estateryss.webp")}
          alt=""
        />
      </div>
    </div>
  );
}

export default EstateryImage;
